/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import Header from "./header"

import "./reset.css"
import "./layout.css"

const Layout = ({ pageTitle, navigateBack, editUrl, hideSearch, children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  const title = pageTitle ? "« " + pageTitle : data.site.siteMetadata.title

  return (
    <>
      <Header
        pageTitle={title}
        navigateBack={navigateBack}
        editUrl={editUrl}
        hideSearch={hideSearch}
      />
      <main>{children}</main>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  pageTitle: PropTypes.string,
  navigateBack: PropTypes.bool,
  editUrl: PropTypes.string,
}

export default Layout
