import { navigate } from "@reach/router"
import PropTypes from "prop-types"
import React from "react"
import styled from "styled-components/macro"
import { Link } from "gatsby"

import { MdSearch, MdAdd, MdEdit } from "react-icons/md"

const HeaderEl = styled.header`
  background: #333;
`
const Layout = styled.div`
  color: white;
  text-decoration: none;
  padding: 12px 12px 6px 12px;
  display: flex;
`
const Title = styled.h1`
  color: white;
  flex-grow: 1;
  cursor: pointer;
`

const Action = styled.div`
  text-align: right;
  margin-left: 12px;
  font-size: 20px;
`

const Header = ({ pageTitle, navigateBack, editUrl, hideSearch }) => {
  function handleClick(e) {
    if (navigateBack) {
      window.history.back()
    } else {
      navigate("/")
    }
  }

  let action = (
    <a
      href="https://kowitz.sanity.studio/intent/create/type=recipe"
      target="_none"
    >
      <MdAdd />
    </a>
  )
  if (editUrl) {
    action = (
      <a href={editUrl} target="_none">
        <MdEdit />
      </a>
    )
  }

  return (
    <HeaderEl>
      <Layout>
        <Title onClick={handleClick}>{pageTitle}</Title>
        {!hideSearch && (
          <Action>
            <Link to="/search">
              <MdSearch />
            </Link>
          </Action>
        )}
        <Action>{action}</Action>
      </Layout>
    </HeaderEl>
  )
}

Header.propTypes = {
  pageTitle: PropTypes.string,
  navigateBack: PropTypes.bool,
  editUrl: PropTypes.string,
}

Header.defaultProps = {
  pageTitle: ``,
  navigateBack: false,
}

export default Header
